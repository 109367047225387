@font-face {
    font-family: 'Sahar-Heavy';
    src: local('Sahar-Heavy'), url('./fonts/Sahar-Heavy_1.ttf') format('truetype');
}

@font-face {
    font-family: 'Sahar-Heavy-Outline';
    src: local('Sahar-Heavy-Outline'), url('./fonts/Sahar-Heavy-Outline.ttf') format('truetype');
}

@font-face {
    font-family: 'Sahar-Regular';
    src: local('Sahar-Regular'), url('./fonts/Sahar-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Sahar-Thin';
    src: local('Sahar-Thin'), url('./fonts/Sahar-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Sahar-Bold`';
    src: local('Sahar-Bold'), url('./fonts/Sahar-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir-Medium`';
    src: local('Avenir-Medium'), url('./fonts/Avenir-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Avenir-Book`';
    src: local('Avenir-Book'), url('./fonts/Avenir-Book.ttf') format('truetype');
}

input:focus, textarea:focus, select:focus, select {
    outline: none;
}

.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    width: 70vw;
    /*height: 70vh;*/
    background-color: #ffffff;
    padding: 4vw;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
}

.carousel > div {
    overflow: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.container {
    height: 100vh;
    width: 100vw;
    transition: all 0.5s ease;
}

.deliver {
    position: absolute;
    top: 90px;
    left: -200px;
    transition: left 3s ease;
}

.enable {
    position: absolute;
    top: 500px;
    right: -200px;
    transition: all 3s;
    text-align: right;
}

.subHeading {
    font-family: 'Sahar-Heavy';
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    /*position: fixed;*/
    /*top: 60px;*/
    /*left: 0;*/
    margin-left: 4vw;
    margin-bottom: 10px;
    /*margin-top: 5px;*/
    /*z-index: 10;*/
    opacity: 0;
    transition: all 2s;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

p {
    margin: 0;
    padding: 0;
}

.nav {
    transition: all 1s ease;
}

body {
    overflow-x: hidden;
}

.menuBox {
    clip-path: circle(5%);
    height: 100vh;
    transition: all 0.5s;
    width: 100vw;
    opacity: 0;
    z-index: 6;
}

.convert {
    clip-path: circle(75%);
    opacity: 1;
}

.verticalsImage:hover {
    transform: scale(1.2);
    transition: all 0.8s;
}

.caseImage {
    height: 28vh;
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 30px 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.caseImage:hover {
    transform: scale(1.2);
    transition: all 0.8s;
    z-index: 5;
}

.caseBox {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
}
.caseName {
    font-family: Sahar-Heavy;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 6vh;
    color: #ffffff;
    margin: auto 0;
}
.caseDesc {
    font-size: 2.2vh;
    color: #ffffff;
    text-align: center;
    font-family: Avenir-Medium;
}


.menuItem:hover {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.strategicItem {
    transition: all 1.2s;
    overflow: hidden;
    padding: 20px 10px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    height: 10vh;
    display: flex;
    flex-direction: column;
    background-color: #609bf2;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 20vw;
    position: relative;
}

.toolClick {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 40px;
    width: 40px;
    background-color: rgba(2, 14, 53, 1);
    border-top-left-radius: 100%;
    z-index: 10;
    overflow: hidden;
}

.toolName {
    align-self: center;
    text-transform: uppercase;
    font-family: Sahar-Heavy;
    font-size: 2.5vh;
    font-weight: bold;
    color: white
}

.toolDesc {
    margin-top: 1vh;
    align-self: center;
    font-size: 2vh;
    text-align: center;
    color: white
}

.logo {
    height: 50vh;
    width: 70vw;
    margin-top: 2vh;
    transition: all 0.5s ease;
}

.calendar {
    width: 30vw;
    height: 50vh;
}

.calendarMobile {
    width: 60vw;
    height: 30vh;
    font-size: 10px;
}

.select {
    background-color: white;
}

.logoCover {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, rgba(19,38,98,255), rgba(116,79,92,255));
    width: 100vw;
    height: 100vh;
    z-index: 15;
}

.fadeOut {
    animation: fadeOut 3s ease-out;
}

.initialLogo {
    width: 80vw;
    height: 80vh;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        height: 100vh;
        /*width: 100vh;*/
    }
    100% {
        opacity: 0;
        height:0;
        /*width: 0;*/
    }
}
